.tagColor{
	padding:15px !important;
	color: #ffffff;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	/*border: 2px solid black;*/
	border-image: linear-gradient(to right, #3acfd5 0%, #3a4ed5 100%) 1;
	border-radius: 5px; /* this doesn't work */
	border-width: 4px;
	border-style: solid;
	padding: 5px;
}
.btn-rainbow {
       color: #fff;    
        background: linear-gradient(270deg,rgb(255, 196, 0) 15%, rgb(255, 234, 0) 50%, rgb(255, 196, 0) 85%);
       /*background: linear-gradient(270deg, #FF4500, #FF4500, red , red,  #FF4500, #FF4500, #FF4500, #FF4500, red);*/
       background-size: 400% 400%;
       animation: gradient 5s ease infinite;
       /*background: linear-gradient(135deg, #FF4500, #FF4500, red , red,  #FF4500, #FF4500, #FF4500, #FF4500, red);*/
  /*background: linear-gradient(135deg, red, orange, red, orange, red);*/
  /*background-size: 200% 200%;*/
  /*animation: rainbow 5s linear infinite;*/
  /*border: 0;*/
  /*color: #fff;*/

  /*
  background: linear-gradient(105deg,
  #f6d365, #fda085, #f6d365, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3,
  #f6d365, #fda085, #f6d365, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3,
  #f6d365);
  
  background-size: 200% 200%;
  animation: rainbow 5s linear infinite;
  border: 0;
  color: #fff;
  */
}

    @keyframes gradient {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
@keyframes rainbow {
    0% { background-position: 100% 100% }
  100% { background-position: 0% 0% }
}