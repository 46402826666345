 .react-colorful {
  width: auto !important;
  height: 200px;
}
 .react-colorful__saturation {
  border-radius: 4px 4px 0 0;
}
 .react-colorful__hue {
  height: 40px;
  border-radius: 0 0 4px 4px;
}
 .react-colorful__hue-pointer {
  width: 12px;
  height: inherit;
  border-radius: 0;
}